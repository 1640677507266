<template>
    <div class="tree-panel__wrap">
        <el-menu-collapse-transition>
            <div class="tree-panel horizontal-collapse-transition" >
                <el-input placeholder="输入线路名称、设备名称" v-model="filterText"></el-input>
                <div class="root-add-box" @click="handleAddRootNode">
                    <i class="el-icon-circle-plus icon-margin-left"></i>增加变电站
                </div>

                <el-scrollbar style="height: calc(100% - 58px)">
                    <el-tree
                        class="filter-tree"
                        :expand-on-click-node="false"
                        :data="treeData"
                        :props="defaultProps"
                        default-expand-all
                        :filter-node-method="filterNode"
                        :highlight-current="true"
                        node-key="id"
                        ref="tree"
                    >
                        <span class="custom-tree-node" slot-scope="{node, data}">
                            <!--<el-tooltip placement="top">
                                <div slot="content">{{ data.name }}</div>-->
                                <div
                                    class="tree-item tree-text"
                                    @contextmenu="showContextMenu(data, $event)"
                                    @click="closeContextmeun(data)"
                                >
                                    {{ data.name }}
                                </div>
                           <!-- </el-tooltip>-->
                        </span>
                    </el-tree>
                </el-scrollbar>
                <!-- 添加新用户 -->
                <el-dialog
                    width="600px"
                    :title="addNewUserDialog.title"
                    v-if="addNewUserDialog.visible"
                    :visible.sync="addNewUserDialog.visible"
                    :modal-append-to-body="true"
                    :close-on-click-modal="false"
                    :append-to-body="true"
                    :show-close="true"
                >
                    <span>添加新用户，将发起业扩流程，是否确定发起业扩流程？</span>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="addNewUserDialog.visible = false" size="mini">{{ '取消' }}</el-button>
                        <el-button type="primary" @click="sendWorkflowList" size="mini">{{ '确定' }}</el-button>
                    </span>
                </el-dialog>
                <!-- 关联图纸 -->
                <el-dialog
                    width="400px"
                    :title="editLineWayDialog.title"
                    v-if="editLineWayDialog.visible"
                    :visible.sync="editLineWayDialog.visible"
                    :modal-append-to-body="true"
                    :close-on-click-modal="false"
                    :append-to-body="true"
                    :show-close="true"
                >
                    <el-form :model="netValues" :rules="netRules" ref="editForm" size="mini">
                        <div>
                            <el-row>
                                <ex-form-item
                                    v-for="(item, index) in netItems"
                                    :key="index"
                                    :item="item"
                                    :formData="netValues"
                                ></ex-form-item>
                            </el-row>
                        </div>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="editLineWayDialog.visible = false" size="mini">{{ '取消' }}</el-button>
                        <el-button type="primary" @click="associatedDrawingsNextStep" size="mini">下一步</el-button>
                    </span>
                </el-dialog>
                <!-- 图纸查看 -->
                <el-dialog
                    width="900px"
                    :title="downDialog.title"
                    v-if="downDialog.visible"
                    :visible.sync="downDialog.visible"
                    :modal-append-to-body="true"
                    :close-on-click-modal="true"
                    :append-to-body="true"
                    :show-close="true"
                >
                    <device-drawing-list
                        :pic="downDialog.pic"
                        @closeForm="downDialog.visible = false"
                    ></device-drawing-list>
                </el-dialog>
                <v-contextmenu ref="tableCellContextmenu">
                    <v-contextmenu-item @click="handleContextAction('add')">
                        <i class="el-icon-circle-plus icon-margin-left"></i>增加子线路
                    </v-contextmenu-item>
                    <v-contextmenu-item @click="handleContextAction('edit')">
                        <i class="el-icon-s-tools icon-margin-left"></i>编辑线路
                    </v-contextmenu-item>
                    <v-contextmenu-item @click="handleContextAction('delete')">
                        <i class="el-icon-delete-solid icon-margin-left"></i>删除
                    </v-contextmenu-item>
                    <v-contextmenu-item divider></v-contextmenu-item>
                    <v-contextmenu-item @click="handleContextAction('addNewUser')">
                        <i class="el-icon-user-solid icon-margin-left"></i>添加新用户
                    </v-contextmenu-item>
<!--                    <v-contextmenu-item @click="handleContextAction('preview')">
                        <i class="el-icon-picture icon-margin-left"></i>查看图纸
                    </v-contextmenu-item>-->
                </v-contextmenu>
            </div>
        </el-menu-collapse-transition>
        <!--<div class="verticality-collapse-trigger" @click="handleToggleCollpase">
            <i :class="collapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
        </div>-->
    </div>
</template>

<script>
import {genItems, pickItemByProp} from '@/utils/formItemUtil';
import {addClass, removeClass, hasClass} from 'element-ui/src/utils/dom';
import DeviceDrawingList from './DeviceDrawingList';
import ElMenuCollapseTransition from './ElMenuCollapseTransition.js';
let netObj = genItems([
    {
        prop: 'name',
        itemType: 'input',
        label: '线路名称',
        rules: [
            {
                required: true,
            },
        ],
    },
    {
        prop: 'drawingApplyId',
        itemType: 'select',
        label: '图纸ID',
        rules: [
            {
                required: true,
            },
        ],
    },
]);
export default {
    components: {
        DeviceDrawingList,
        ElMenuCollapseTransition,
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    data() {
        let {items: netItems, values: netValues, rules: netRules} = netObj;
        return {
            collapse: false,
            netItems,
            netValues,
            netRules,
            filterText: '',
            treeData: [],
            downDialogData: {
                url: '',
            },
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            addNewUserDialog: {
                visible: false,
                title: '',
                data: null,
                type: '',
            },
            editLineWayDialog: {
                visible: false,
                title: '',
            },
            downDialog: {
                visible: false,
                title: '',
                data: null,
                type: '',
            },
        };
    },
    methods: {
        /* handleToggleCollpase() {
            this.collapse = !this.collapse;
        },*/
        showContextMenu(data, e) {
            e.preventDefault();
            if (data.powerGridType === 1) {
                this._contextItemData = data;
                this.$refs.tableCellContextmenu.show({
                    left: e.clientX,
                    top: e.clientY,
                });
            }
        },
        handleAddRootNode() {
            this._contextItemData = null;
            this.handleContextAction('add');
        },
        closeContextmeun(data) {
            this.$emit('node-click', data);
            this.$refs.tableCellContextmenu.hide();
        },
        handleContextAction(action) {
            let data = this._contextItemData;
            if (action === 'addNewUser') {
                this.addNewUserDialog = {
                    title: '添加新用户',
                    data,
                    visible: true,
                    type: 'add',
                };
            } else if (action === 'preview') {
                this.getPreviewDrawing(data);
            } else if (action === 'edit') {
                this.netValues = this._.cloneDeep(this._contextItemData);
                this._netValues = this._.cloneDeep(this._contextItemData);
                this.editLineWayDialog = {
                    visible: true,
                    title: '配网维护',
                    type: 'edit',
                };
            } else if (action === 'add') {
                this.netValues = {parentId: data ? data.id : undefined};
                this.editLineWayDialog = {
                    visible: true,
                    title: '新增线路',
                    type: 'add',
                };
            } else if (action === 'delete') {
                if (data.hasChildGrid && data.children && data.children.length) {
                    this.$message({
                        message: '请先删除此线路的所有子线路，再删除此线路',
                        type: 'error',
                    });
                } else {
                    this.$confirm('确认删除 [ ' + data.name + ' ]线路？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.doRemoveTreeNode(data);
                    });
                }
            }
        },
        getPreviewDrawing(data) {
            this.$client.getDrawingApplyDetail(data.drawingApplyId).then((res) => {
                let {drawing} = res;
                this.downDialog = {
                    title: '图纸查看',
                    data,
                    visible: true,
                    type: 'edit',
                    pic: drawing,
                };
            });
        },
        doRemoveTreeNode(data) {
            this.$client.delPowerGrids(data.id).then((res) => {
                this.getPowerGrid();
            });
        },
        filterNode(value, data) {
            if (!value) {
                return true;
            }
            return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        },
        validate(form) {
            return new Promise((resolve) => {
                if (!form) {
                    resolve(true);
                    return;
                }
                form.validate((valid) => {
                    if (valid) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        },
        // 发起业扩流程
        sendWorkflowList(data) {
            this.$router.push({name: 'BusinessApplyForm', params: {routeMode: true}});
        },
        // 关联图纸 下一步
        async associatedDrawingsNextStep() {
            const valid = await this.validate(this.$refs.editForm);
            if (!valid) {
                // this.$message({
                //     message: '请检查输入数据',
                //     type: 'error',
                // });
            } else {
                // 变电站
                // this.$router.replace({
                //     name: 'TransformerSubstation',
                //     params: Object.assign({id: this.associatedDrawingsDialog.data.id}, this.associatedDrawingsData),
                // });
                let parentData = this._contextItemData;
                let data = this.netValues;
                if (!this._.isEqual(this.netValues, this._netValues)) {
                    if (data.id) {
                        this.$client.updatePowerGrids(data.id, data).then((res) => {
                            this.getPowerGrid();
                            this.$router.push(`/powerGridMonitor/edit/${res.data.id}`);
                        });
                    } else {
                        this.$client.savePowerGrids(data).then((res) => {
                            this.getPowerGrid();
                            this.$router.push(`/powerGridMonitor/edit/${res.data.id}`);
                        });
                    }
                } else {
                    this.$router.push(`/powerGridMonitor/edit/${data.id}`);
                }
            }
        },
        getPowerGrid() {
            this.$client.getPowerGridsTrees().then((res) => {
                let treeData = res.data;
                this.stringHandler(treeData);
                this.treeData = treeData;
                let firstItem = treeData[0];
                if (firstItem) {
                    this.$nextTick(() => {
                        this.$refs.tree.setCurrentKey(firstItem.id);
                        this.$emit('node-click', firstItem);
                    });
                }
            });
        },
        //接收树状数组引用，然后对节点name进行数据清洗
        stringHandler(treeData) {
            for (let i = 0; i < treeData.length; i++) {
                let tree = treeData[i];
                if (tree.name) {
                    tree.name = tree.name.replace('环网柜-', '');
                }
                if (tree.children && tree.children.length > 0) {
                    this.stringHandler(tree.children);
                }
            }
        },
    },
    created() {
        this.getPowerGrid();
        // 获取图纸id下拉选项
        this.$client.getDrawingApply().then((res) => {
            let selectInput = pickItemByProp(this.netItems, 'drawingApplyId');
            let option = res.data.map((it) => {
                return {
                    key: it.drawingName,
                    value: it.id,
                };
            });
            // 设置图纸ip选项options
            if (selectInput) {
                this.$set(selectInput.options, 'option', option);
            }
        });
    },
};
</script>

<style lang="less" scoped>
.tree-panel {
    height: calc(100% - 28px);
    padding: 10px;
    // /deep/ .el-input__inner {
    //     background-color: #fff;
    //     border-radius: 4px;
    //     border: 1px solid #adadad;
    //     color: #606266;
    // }
    // /deep/ .el-input__inner::placeholder {
    //     color: #c0c4cc;
    // }
    /deep/ .filter-tree {
        height: calc(100% - 58px);
    }
    // /deep/ .el-tree {
    //     color: #606266;
    // }
    &_hide {
        display: none;
    }
    &__wrap {
        height: 100%;
        position: relative;
    }

    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        padding-right: 8px;
    }
}
.tooltip-icon i {
    margin-left: 5px;
    cursor: pointer;
}
// /deep/ .custom-tree-node {
//     height: 26px;
// }
/deep/ .tree-item {
    flex-grow: 1;
    height: 26px;
    line-height: 26px;
    user-select: none;
}
.root-add-box {
    font-size: 14px;
    // color: #606266;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    &:hover {
        color: #486dd5;
    }
}
.icon-margin-left {
    margin-right: 5px;
}
</style>
