<template>
    <div class="monitor-wrap" >
        <div class="monitor-wrap__left" ref="monitor-wrap">
            <device-line-tree ref="lineDeviceTree" @node-click="handleNodeClick"></device-line-tree>
        </div>
        <div class="monitor-wrap__center" @mousedown="handleDown"><el-button class="button-type" icon="el-icon-c-scale-to-original"></el-button></div>
        <div id="tuozhuai" class="monitor-wrap__right">
            <!--<div style="margin-top: 5px;margin-bottom: 5px">
                <el-button @click="mapClick" style="text-align:center;width: 44px; height: 44px; border-radius: 22px;padding: 0px">地图</el-button>
                <el-button @click="topologyClick" style="text-align:center;width: 44px; height: 44px; border-radius: 22px;padding: 0px">拓扑图</el-button>
            </div>-->
            <device-legend-panel
                ref="DeviceLegendPanel"
                :allPoints="allPoints"
                :withHeader="true"
                @showPointsChange="handleShowPointsChange"
                v-if="showMap"
            ></device-legend-panel>
            <map-base
                @itemClick="handleMapItemClick"
                @mapLayerClick="handleMapLayerClick"
                :lines="lines"
                :gridLines="gridLines"
                :points="showPoints"
                ref="mapbase"
                v-if="showMap"
            ></map-base>
            <topology v-if="showTopology" ref="Topology"></topology>
        </div>
    </div>
</template>

<script>
import DeviceLineTree from './DeviceLineTree';
import DeviceLegendPanel from './DeviceLegendPanel';
import MapBase from './MapBase';
import mapCommon from './mapCommon';
import {equipmentTypes, equipmentTypeTrans} from './utils';
import Topology from './Topology';
export default {
    mixins: [mapCommon],
    components: {
        DeviceLineTree,
        MapBase,
        DeviceLegendPanel,
        Topology,
    },
    props: {
        deviceConfigurationanelOpen: {
            type: String,
            default: 'open',
        },
    },
    // computed: {
    // },
    // watch: {
    // },
    data() {
        return {
            selecLine: null,
            lines: [],
            gridLines: [],
            showPoints: [],
            allPoints: [],
            showMap: true,
            showTopology: false,
        };
    },
    methods: {
        handleDown(edown) {
            let left = document.querySelector('.monitor-wrap__left');
            const kk = this.$refs['monitor-wrap'].getBoundingClientRect();
            document.onmousemove = function (eMove) {
                left.style.width = eMove.x - kk.x + 'px';
            };
            document.onmouseup = function(evt) {
                console.log(evt)
                evt.stopPropagation();
                document.onmousemove = null;
                document.onmouseup = null;
                left.releaseCapture && left.releaseCapture();
            };
            left.setCapture && left.setCapture();
        },
        mapClick() {
            this.showMap = true;
            this.showTopology = false;
        },
        topologyClick() {
            this.showMap = false;
            this.showTopology = true;
        },
        handleNodeClick(data) {
            if (data.powerGridType === 1) {
                this.selecLine = this._.cloneDeep(data);
                this.getLayerData(this.selecLine.id);
            } else {
                this.$refs.mapbase.setEquipmentLocation(data);
            }
        },
        handleMapItemClick(type, index, data) {
            if (type === 'point') {
                this.showPopper(data);
            }
        },
    },
    created() {},
};
</script>

<style lang="less" scoped>
.monitor-wrap {
    height: 100%;
    display: flex;
    align-items: center;

    &__left {
        height: 100%;
        width: 300px;
    }
    .monitor-wrap__center {
        cursor: e-resize;
        z-index: 99999;
        width: 12px;
    }
    .button-type {
        background-color: #235eff;
        width: 24px;
        height: 12px;
        padding: 0;
    }
    &__right {
        flex: 1;
        position: relative;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}
</style>
