<template>
    <div>
        <el-tabs v-model="activeName">
            <el-tab-pane
                :label="`${drawingTypeTrans[colIndex]} (${col.length})`"
                :name="colIndex"
                v-for="(col, colIndex) in picData"
                :key="colIndex"
            >
                <el-row class="image-preview">
                    <div class="image-preview__box-wrap" v-if="col.length">
                        <el-col :span="8" class="image-preview__box" v-for="(item, index) in col" :key="index">
                            <el-image
                                @click="showPdf(item)"
                                :src="getPreviewUrl(item)"
                                class="image-preview__image"
                                fit="contain"
                            ></el-image>
                            <div class="image-preview__describe">
                                <span>图纸名称：{{ item.drawingName }}</span>
                                <span>图纸编号：{{ item.drawingCode }}</span>
                                <span class="image-preview__describe-btns"
                                    ><el-button @click="showPdf(item)" size="mini" type="primary">预览</el-button
                                    ><el-button @click="downloadPdf(item)" size="mini" type="primary"
                                        >下载</el-button
                                    ></span
                                >
                            </div>
                        </el-col>
                    </div>
                    <div class="image-preview__empty" v-else>暂无图纸</div>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        <div style="width: 100%; text-align: center; margin-top: 50px">
            <el-button @click="closeForm" type="primary">关闭</el-button>
        </div>
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';
export default {
    props: {
        pic: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            activeName: 'drawing10',
            drawingTypeTrans: {
                drawing10: '开关站单线图',
                drawing20: '开关站主线图',
                drawing30: '变电站主线图',
            },
        };
    },
    computed: {
        picData() {
            let pic = this.pic || {};
            return {
                drawing10: pic.drawing10 || [],
                drawing20: pic.drawing20 || [],
                drawing30: pic.drawing30 || [],
            };
        },
    },
    created() {},
    methods: {
        getPreviewUrl(pic) {
            let url = `${this.$client.serverUrl}files/${pic.ossId}/thumbnail?token=${this.$store.state.token}`;
            return url;
        },
        getDownloadUrl(pic) {
            let url = this.axios.defaults.baseURL + '/files/' + pic.ossId + '?token=' + this.$store.state.token;
            return url;
        },
        downloadPdf(pic) {
            fileDownload(this.getDownloadUrl(pic));
        },
        showPdf(pic) {
            previewPdf(this.getDownloadUrl(pic), pic.drawingName + '  ' + pic.drawingCode);
        },
        closeForm() {
            this.$emit('closeForm');
        },
    },
};
</script>
<style lang="less" scoped>
.image-preview {
    height: 450px;
    overflow: auto;
    &__box-wrap {
    }
    &__empty {
        width: 100%;
        min-height: 300px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__box {
        text-align: center;
    }
    &__describe {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__image {
        cursor: pointer;
        width: auto;
        height: 250px;
        width: 80%;
    }
    &__describe-btns {
        margin-top: 5px;
    }
    &__empty {
    }
}
</style>