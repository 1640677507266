<template>
    <div>
        <div id="Topology" ref="Topology"></div>
    </div>
</template>

<script>
import OrgChart from '@balkangraph/orgchart.js';
export default {
    name: 'Topology',
    data() {
        return {
            nodes: [
                {id: 1, name: 'Denny Curtis', title: 'CEO', img: 'https://cdn.balkan.app/shared/2.jpg'},
                {id: 2, pid: 1, name: 'Ashley Barnett', title: 'Sales Manager', img: 'https://cdn.balkan.app/shared/3.jpg'},
                {id: 3, pid: 1, name: 'Caden Ellison', title: 'Dev Manager', img: 'https://cdn.balkan.app/shared/4.jpg'},
                {id: 4, pid: 2, name: 'Elliot Patel', title: 'Sales', img: 'https://cdn.balkan.app/shared/5.jpg'},
                {id: 5, pid: 2, name: 'Lynn Hussain', title: 'Sales', img: 'https://cdn.balkan.app/shared/6.jpg'},
                {id: 6, pid: 3, name: 'Tanner May', title: 'Developer', img: 'https://cdn.balkan.app/shared/7.jpg'},
                {id: 7, pid: 3, name: 'Fran Parsons', title: 'Developer', img: 'https://cdn.balkan.app/shared/8.jpg'}
            ],
            // testImg: require('../../static/test.jpg'),
            // testImg: require('../../assets/icons/biandianzhan.png'),
            // testImg: require('../../assets/icons/test.jpg'),
        };
    },
    methods: {
        oc(domEl, x) {
            const that = this;
            this.$client.getPowerGridsTopology().then((res) => {
                let treeData = res.data;
                if (treeData) {
                    let pData = [];
                    for (let i = 0; i < treeData.length; i++) {
                        let item = {
                            id: treeData[i].id,
                            name: treeData[i].name,
                            pid: treeData[i].parentId,
                            img: that.testImg,
                        };
                        pData.push(item);
                    }
                    x = pData;
                    this.chart = new OrgChart(domEl, {
                        template: 'diva',
                        // template: 'myTemplate',
                        nodes: x,
                        nodeBinding: {
                            field_0: 'name',
                            field_1: 'title',
                            img_0: 'img'
                        }
                    });
                }
            });
        }
    },
    mounted() {
        this.oc(this.$refs.Topology, this.nodes);
    }
};
</script>
